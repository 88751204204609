import React from 'react'

const Conditions = () => {
    return (
        <div className='max-w-[2000px] mx-auto'>
            <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <img className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://termshub.io/v3/assets/images/products/terms_of_service_hero.svg" />
                    <div className="text-left lg:w-2/3 w-full">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Terms & Conditions</h1>
                        <p className='my-3 leading-relaxed'>
                            It is important to read and understand the following terms and conditions before using the website.

                        </p>
                        <p className='my-3 leading-relaxed'>
                            These terms and conditions tell you the rules of use of this website https://couponsdiscount.uk.

                        </p>
                        <p className='my-3 leading-relaxed'>
                            By using our website you accept the following conditions.
                        </p>
                        <p className='text-[20px] font-bold my-3'>Terms of Use</p>
                        <li className='my-3 leading-relaxed'>
                            By using this website, you agree to these Terms of Use.
                        </li>
                        <li className='my-3 leading-relaxed'>
                            If you disagree with these terms, refrain from using this website.
                        </li>
                        <li className='my-3 leading-relaxed'>
                            Our Terms of Use include our privacy and cookie policy.
                        </li>
                        <li className='my-3 leading-relaxed'>
                            Your use of this website implies consent to the processing of collected data and accuracy of provided
                            information.
                        </li>
                        <p className='text-[20px] font-bold my-3'>Changing Conditions</p>
                        <p className='my-3 leading-relaxed'>
                            Website Terms and conditions will be changed from time to time. Please check our terms and conditions whenever
                            you want to use this website.
                        </p>
                        <p className='text-[20px] font-bold my-3'>Change the site</p>
                        <p className='my-3 leading-relaxed'>
                            The website may be changed time to time.
                        </p>
                        <p className='text-[20px] font-bold my-3'>Using Website</p>
                        <p className='my-3 leading-relaxed'>
                            This website is free to use but we cannot guarantee its continuous availability or functionality. It&#39;s intended for UK
                            residents and provides general information, though we cannot assure its reliability or timeliness. We strive to update
                            its content regularly, but accuracy and currency are not guaranteed.
                        </p>
                        <p className='text-[20px] font-bold my-3'>Responsibility</p>
                        <p className='my-3 leading-relaxed'>
                            We are not responsible for websites and the contents we link to.
                        </p>
                        <p className='my-3 leading-relaxed'>
                            In cases where this website has links to the other websites, these links are provided for your information. We have
                            no control for the content of those websites
                        </p>

                    </div>
                </div>
            </section>
        </div>
    )
}



export default Conditions
