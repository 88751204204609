import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import "./Shop.css";
import Modal from "../Components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { GetAllOffer } from "../redux/actions/OfferActions";
import Loading from "../Components/spinner/Loading";
import { GetStoreList } from "../redux/actions/StoreActions";
import OfferpageCompo from "../Components/offerPageComponent/OfferpageCompo";
import moment from 'moment';

const Shop = () => {
  const { name } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState({});
  const offerList = useSelector((state: any) =>
    state.offer.offerList?.filter(
      (item: any) => item?.store?.storeName === name
    )
  );


  const NewofferList = offerList?.filter((item: any) => {
    const today = new Date();
    const endDate = new Date(item?.endDate);
    return endDate >= today;
  });

  // Filter expired offers (endDate < today)
  const expiredOfferList = offerList?.filter((item: any) => {
    const today = new Date();
    const endDate = new Date(item?.endDate);
    return endDate < today;
  });
    


  const StoreList = useSelector((state: any) =>
    state.Store.StoreList?.slice()
  );
  const offerLoading = useSelector((state: any) => state.offer.offerLoading);

  const dispatch = useDispatch();
  useEffect(() => {
    // @ts-ignore
    dispatch(GetAllOffer());
    if (StoreList?.length === 0) {
      // @ts-ignore
      dispatch(GetStoreList());
    }
  }, []);

  return (
    <div className="max-w-[2000px] bg-[#F2F3F4] 2xl:px-[150px] px-[10px] md:px-[50px] py-[20px] text-primaryColor">
      {offerLoading ? (
        <Loading />
      ) : offerList?.length > 0 ? (
        <div className="">
          {/* --------  */}
          <div className="Shop_page">
            <div className="flex justify-start place-items-start gap-[20px] border-b-[1px] border-b-[white] pb-[30px] shop_hero">
              <img
                src={offerList && offerList[0]?.store?.storeLogo?.url}
                className="rounded-md w-[200px] object-contain h-[120px] cursor-pointer drop-shadow-md"
                alt=""
              />
              <div>
                <h2 className="text-[30px] font-bold">
                  {offerList && offerList[0]?.store?.storeName}
                </h2>
                <p className="text-[14px] text-[gray] max-w-[600px]">
                  {/* {offerList &&
                    offerList[0]?.store?.metaDescription?.slice(0, 180)}
                  ... */}
                  {offerList && offerList[0]?.store?.storeName} discounts, vouchers codes and offers - {moment().format('MMMM, YYYY')}.
                </p>
              </div>
            </div>
            <div className="home_grid">
              <OfferpageCompo 
                 NewofferList={NewofferList}
                 expiredOfferList={expiredOfferList}
                 setShowModal={setShowModal}
                 setItem={setItem}
              />
              {/* =====  */}
              <div className="border-[1px] bg-[#F2F3F4] py-[0px] px-[10px] border-[#F2F3F4] rounded-md">
                <div className="mt-5">
                  <h1 className="mb-2 text-[20px] font-medium">
                    Popular shops
                  </h1>
                  <div className=" p-[10px] rounded-md sidebar_shops">
                    {StoreList &&
                      StoreList?.slice(-10)?.map((item: any, index: any) => {
                        return (
                          <NavLink
                            to={`/shop/${item?.storeName}`}
                            className=" p-2 justify-start  gap-[10px] place-items-start w-[100%]  flex-shrink-0 h-[110px] rounded-lg hover:shadow-lg cursor-pointer border-[1px] border-[white]"
                            key={index}
                          >
                            <div className="w-[100%] relative  h-[100%] flex gap-1 flex-col justify-center place-items-center">
                              <img
                                src={item?.storeLogo?.url}
                                alt=""
                                className="w-[100px] h-[90px]  object-contain rounded-md overflow-hidden"
                              />
                              <p>
                                {item?.offers ? item?.offers?.length : "0"}{" "}
                                Offers
                              </p>
                            </div>
                          </NavLink>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-[20px] w-[100%] text-center my-5 font-bold">
          No offer found
        </p>
      )}

      {/* === modal  */}
      {showModal && (
        <div className={""}>
          {
            <Modal
              setShowModal={setShowModal}
              showModal={showModal}
              item={item}
            />
          }
        </div>
      )}
    </div>
  );
};

export default Shop;
