import React, { useEffect, useState } from "react";
import "../Shops/Shops.css";
import Modal from "./Modal";
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import NewSpinner from "../../Components/spinner/NewSpinner";
import { DeleteOffer, GetAllOffer } from "../../redux/actions/OfferActions";

const Offers = () => {
  const [showModal, setShowModal] = useState(false);
  const offerList = useSelector((state: any) => state.offer.offerList);
  const offerLoading = useSelector((state: any) => state.offer.offerLoading);
  const [isEdit, setIsEdit] = useState(false);
  const [editOffer, setEditOffer] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    // @ts-ignore
    dispatch(GetAllOffer());
  }, []);
  return (
    <div className="w-[100%] d_shops">
      <div className="w-[100%] my-[20px] py-3 flex justify-between items-center">
        <h2 className="text-[35px] font-medium">{offerList?.length} Offers.</h2>
        <button
          onClick={() => setShowModal(true)}
          className="bg-primaryColor text-[white] px-[20px] py-[9px] rounded-md"
        >
          + add new Offers
        </button>
      </div>

      {/* --- table  */}
      <div className="h-[400px] overflow-y-auto  overflow-x-auto w-[100%]">
        <table className="min-w-[350%]">
          <thead className="w-[100%]  bg-secondaryColor">
            <tr className=" w-[100%]  text-[white]">
              <th className="text-left">Offer Name</th>
              <th className="text-left">Network</th>
              <th className="text-left">Meta title</th>
              <th className="text-left">Expire data</th>
              <th className="text-left">CouponCode</th>
              <th className="text-left">FreeShipping</th>
              <th className="text-left">Discount</th>
              <th className="text-left">Meta description</th>
              <th className="text-left">Store Category</th>
              <th className="text-left">Store</th>
              <th className="text-left">Affliate Link</th>
              <th className="text-left">Actions</th>
            </tr>
          </thead>
          {/* -- bod y */}
          {offerLoading ? (
            <div className="my-2">
              <NewSpinner />
            </div>
          ) : (
            <tbody style={{padding : "0px 8px"}}>
              {offerList &&
                offerList?.map((item: any, index: any) => {
                  return (
                    <tr className="bg-[#eeeeee]  !text-left border-b-[1px] border-b-primaryColor">
                      <td className="!text-left ">{item?.offerName}</td>
                      <td className="!text-left ">Network</td>
                      <td className="!text-left ">{item?.metaTitle}</td>
                      <td className="!text-left ">{item?.endDate?.slice(0,10)}</td>
                      <td className="!text-left ">
                        {item?.CouponCode ? item?.CouponCode : "-"}
                      </td>
                      <td className="!text-left ">{item?.freeShipping}</td>
                      <td className="!text-left ">
                        {item?.discount ? item?.discount : "-"}
                      </td>
                      <td className="!text-left ">
                        {item?.metaDescription?.slice(0, 50)}...
                      </td>
                      <td className="!text-left ">{item?.category?.CategoryName}</td>
                      <td className="!text-left ">{item?.store?.storeName}</td>
                      <td className="!text-left ">
                        {item?.affliateLink?.slice(0, 30)}...
                      </td>
                      <td className="flex justify-center items-center gap-2">
                        <MdDeleteOutline
                          className="text-[23px] text-[red] cursor-pointer"
                          onClick={() => {
                            // @ts-ignore
                            dispatch(DeleteOffer(item?._id));
                          }}
                        />
                        <FaEdit
                          className="text-[23px] cursor-pointer text-[green]"
                          onClick={() => {
                            setShowModal(true)
                            setEditOffer(item);
                            setIsEdit(true);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
      </div>
      {showModal 
         && <Modal setShowModal={setShowModal} showModal={showModal}
         serIsEdit={setIsEdit}
         Item={editOffer}
         isEdit={isEdit}
         setEditOffer={setEditOffer}

        />}
    </div>
  );
};

export default Offers;
