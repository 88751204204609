import React from "react";
import r1 from "../assets/r1.jpeg";
import r2 from "../assets/r2.jpeg";


const Redeem = () => {
  return (
    <div className="max-w-[2000px] bg-[#F2F3F4] 2xl:px-[150px] px-[10px] md:px-[50px] py-[30px] min-h-[100vh]">
      <h1 className="mb-5 text-[25px] sm:text-[20px] font-bold">How do i redeem a coupon code?</h1>
      <h2 className="mb-2 text-[18px] font-bold">Discounts and Coupon Codes</h2>
      <p className="max-w-[1000px] mb-2 ">Couponsdiscount.uk provides regular updates and offers on discounts and promotional codes from a wide range of brands. Please follow the steps below to redeem these discount vouchers.</p>
      <div className="flex flex-col gap-3">
        <div>
            <p className="font-medium">1. Select your coupon from the website and click on it.</p>
            <img src={r1} alt="" className="w-[100%] h-auto my-2 border-[1px] border-[gray] rounded-md shadow-md" />
        </div>
        <div>
            <p className="font-medium">2. In the opened popup, copy your coupon code and click on “continue” button.</p>
            <img src={r2} alt="" className="w-[100%] h-auto my-2 border-[1px] border-[gray] rounded-md shadow-md" />
        </div>
        <div>
            <p className="font-medium">3. By clicking this button, you will be redirected to the store page of the selected product provider. Locate the desired product on the store's website and, at checkout, enter the discount code to apply your savings.
            </p>
        </div>
      </div>
    </div>
  );
};

export default Redeem;

