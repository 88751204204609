import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className='max-w-[2000px] mx-auto'>
            <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <img className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://static.vecteezy.com/system/resources/thumbnails/004/948/248/small_2x/privacy-policy-illustration-design-concept-free-vector.jpg" />
                    <div className="text-left lg:w-2/3 w-full">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-bold text-gray-900">Privicy Policy</h1>
                        {/* <h1 className='text-[20px] font-bold my-3'>We are a UK company:</h1>
                        <p className='text-[20px] font-bold my-3'>Based in: UK</p>
                        <p className='text-[20px] font-bold my-3'>VAT registration number: UK958202663109</p>
                        <p className='text-[20px] font-bold my-3'>Email: shoppingspot46@gmail.com</p>
                        <p className='text-[20px] font-bold my-3'>Phone: +768412763m</p> */}
                        <p className='my-3 leading-relaxed'>
                            This Policy explains how we protect and handle any personal information you
                            provide to us.

                        </p>
                        <p className='my-3 leading-relaxed'>
                            By using our website, you agree that we may use your personal information
                            as mentioned in this Policy.
                        </p>
                        <p className='text-[20px] font-bold my-3'>Who we are</p>
                        <p className="my-3 leading-relaxed">
                            We are a UK company that provides visitors to the website (couponsdiscount.uk)
                            the information about deals and coupons.
                        </p>
                        <p className='text-[20px] font-bold my-3'>What kinds of information do we collect</p>
                        <p className="my-3 leading-relaxed">
                            We collect the information(Only Email Address) that you choose to provide for
                            us by subscribing to the newsletter.
                        </p>
                        <p className='text-[20px] font-bold my-3'>How we use your data</p>
                        <p className="my-3 leading-relaxed">
                            We will only use the e-mail address to inform you about new offers. We will
                            not pass your data on to third parties. You can send a request to cancel your
                            subscription by sending an email.
                        </p>
                        <p className='text-[20px] font-bold my-3'>Tracking and Cookies</p>
                        <p className="my-3 leading-relaxed">
                            We use Google Analytics which is a web analytics service provided by Google
                            in order to improve our services. Read more about the Google Analytics in the
                            below link.

                        </p>
                        <p className="my-3 leading-relaxed">
                            https://www.google.com/intl/en/policies/privacy/

                        </p>
                        <p className='text-[20px] font-bold my-3'>Updates to this Policy</p>
                        <p className="my-3 leading-relaxed">
                            We reserve the right to make changes to the website's Policy. Please check
                            this page regularly for any changes to the website's Policy.

                        </p>
                        <p className='text-[20px] font-bold my-3'>Contact Us</p>
                        <p className="my-3 leading-relaxed">
                            If you have any questions or comments about this Policy, please contact us by
                            email at:

                        </p>
                        <p className="my-3 leading-relaxed">
                        Affiliatespot429@gmail.com

                        </p>

                    </div>
                </div>
            </section>
        </div>
    )
}


export default PrivacyPolicy
