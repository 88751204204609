import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom';
import { GetAllCategory } from '../redux/actions/categoryActions';
import Loading from '../Components/spinner/Loading';

const AllCategories = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        // @ts-ignore
        dispatch(GetAllCategory())

    }, []);
    const categoryList = useSelector((state: any) => state.category.categoryList)
    const categoryLoading = useSelector((state: any) => state.category.categoryLoading)
    return (
        <div className='max-w-[2000px] 2xl:px-[150px] px-[50px] my-[10px] py-[20px] text-primaryColor'>
            <h1 className='text-center md:text-[30px] text-[23px] my-[20px]'>Popular Categories</h1>
            {
                categoryLoading ?
               <Loading/> :
               <div className='flex justify-center place-items-center gap-[20px] flex-wrap mt-5'>
                {categoryList &&
                    categoryList?.map((item: any, index: any) => {
                        return (
                            <NavLink to={`/categories/${item?.CategoryName}`} className='flex  justify-between gap-[10px] px-[20px] bg-[#F2F3F4] place-items-center w-[300px] flex-shrink-0 h-[70px] rounded-md hover:shadow-lg cursor-pointer border-[1px] border-[#e4e3e3b2]' key={index}>
                                <h2 className='font-bold w-[100%] py-[10px] text-left  text-[14px]'>{item?.CategoryName}</h2>
                                <img src={item?.CategoryImage?.url} alt="" className='rounded-md w-[80px] h-[50px] object-contain' />
                            </NavLink>
                        )
                    })
                }
            </div>
            }

        </div>
    )
}

export default AllCategories