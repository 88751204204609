import React from 'react';
import {MultiCarousal} from "reactui-circle";
import slider1 from "../../../assets/Banner 1.png"
import slider2 from "../../../assets/Banner 2.png"
import slider3 from "../../../assets/Banner 3.png"
import slider4 from "../../../assets/Banner 4.png"
import slider5 from "../../../assets/Banner 5.png"
import "./Hero.css"


const Hero = () => {

    const data = [
        {
            id : 1,
            image :  slider1
        },
        {
            id : 2,
            image :  slider2
        },
        {
            id : 3,
            image :  slider3
        },
        {
            id : 4,
            image :  slider4
        },
        {
            id : 5,
            image :  slider5
        },
    ]


  return (
    <div className='hero_main_div w-[100%] relative z-[-1]  h-[420px] bg-[#F2F3F4] max-w-[2000px] 2xl:px-[150px] px-[10px] py-[20px] md:px-[50px]'>
          <MultiCarousal
           hidebuttons={true}
           data={data}
           breakPoints={{
             0 : {
                slidesPerView:  1,
                spaceBetween:  20
             }
           }}
           Component={CarousalComponent}
           Carousalheight={370}
          />
    </div>
  )
}

export default Hero;


const CarousalComponent = ({item}:any)=>{
    return(
        <img src={item?.image} alt="" className='w-[100%] h-[100%] object-cover rounded-lg cursor-pointer' />
    )
}



