import toast from "react-hot-toast"
import { URL } from "../../setting/setting"
import { CREATE_CATEGORY_REQUEST, CREATE_CATEGORY_REQUEST_ERROR, CREATE_CATEGORY_REQUEST_FAIL, CREATE_CATEGORY_REQUEST_SUCCESS } from "../constants/CategoryConstant"




// --- create store admin 
export const CreateCategory = (formdata)=> async(dispatch)=>{
    try {
        dispatch({type : CREATE_CATEGORY_REQUEST})
        const res  = await fetch(`${URL}/category/create`,{
            method : "POST",
            headers : {
                "Content-Type": "application/json",
                token : localStorage.getItem("Utoken")
            },
            body : JSON.stringify(formdata)

        })
        dispatch({type : CREATE_CATEGORY_REQUEST_FAIL})
        const data = await res.json()
        if(res.status === 400){
            return toast.error(data.message)
        }
        if(res.status === 500){
            return toast.error("internal server error")
        }
            toast.success(data.message)
            dispatch({type : CREATE_CATEGORY_REQUEST_SUCCESS, payload : data.category})
        

    } catch (error) {
        toast.error(error.message)
        dispatch({type : CREATE_CATEGORY_REQUEST_ERROR})
    }
} 


// --- get list  
export const GetAllCategory = () => async(dispatch)=>{
    try {
        dispatch({type : CREATE_CATEGORY_REQUEST})
        const res  = await fetch(`${URL}/category/all`,{
            method : "GET",
            headers : {
                "Content-Type": "application/json",
            },

        })
        dispatch({type : CREATE_CATEGORY_REQUEST_FAIL})
        const data = await res.json()
        console.log(data , "this is data")
        if(res.status === 400){
            return console.log(data.message)
        }
        if(res.status === 500){
            return toast.error("internal server error")
        }
            console.log(data.message)
            dispatch({type : CREATE_CATEGORY_REQUEST_SUCCESS, payload : data.category})
        

    } catch (error) {
        console.log(error.message)
        dispatch({type : CREATE_CATEGORY_REQUEST_ERROR})
    }
} 

// === delete category 
export const DeleteCategory = (id) => async(dispatch)=>{
    try {
        dispatch({type : CREATE_CATEGORY_REQUEST})
        const res  = await fetch(`${URL}/category/delete/${id}`,{
            method : "DELETE",
            headers : {
                "Content-Type": "application/json",
                token : localStorage.getItem("Utoken")
            },

        })
        dispatch({type : CREATE_CATEGORY_REQUEST_FAIL})
        const data = await res.json()
        console.log(data , "this is data")
        if(res.status === 400){
            return toast.error(data.message)
        }
        if(res.status === 500){
            return toast.error("internal server error")
        }
            toast.success(data.message)
            dispatch({type : CREATE_CATEGORY_REQUEST_SUCCESS, payload : data.category})
        

    } catch (error) {
        console.log(error.message)
        dispatch({type : CREATE_CATEGORY_REQUEST_ERROR})
    }
} 