import React, { useEffect, useState } from 'react';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { IoLogoReddit } from "react-icons/io5";
import { FaLinkedinIn } from "react-icons/fa";
import { FaRegNewspaper } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import { IoLogoTiktok } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { NewsLetter } from '../../redux/actions/UserActions';
import toast from 'react-hot-toast';




const Footer = () => {
  const [email , setEmail]  = useState("")
  const [loading , setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleSubscription = async ()=>{
    if(!email){
      toast.error('Please enter email')
      return
    }
    setLoading(true)
    // @ts-ignore
   await dispatch(NewsLetter(email))
    setLoading(false)
    setEmail("")
  }

  return (
    <div className='max-w-[2000px] bg-[#F2F3F4] 2xl:px-[150px] px-[10px] md:px-[50px]  text-primaryColor'>
      <footer className="text-gray-600 body-font">
        <div className="container md:px-5 py-16 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
          <div className="md:w-96 w-[100%] flex-shrink-0 md:mx-0 mx-auto text-left md:text-left">
            <a className="flex title-font font-medium items-center md:justify-start justify-start text-gray-900">
              <FaRegNewspaper className='text-[28px]'/>
              <span className="ml-3 text-xl">Newsletter</span>
            </a>
            <p className="mt-2 text-sm text-gray-500">You can subscribe to the CouponsDiscount.uk Newsletter here.</p>
            <input type="text" value={email} onChange={(e)=> setEmail(e.target.value)} placeholder='enter email' className='w-[100%] my-2 px-2 py-2 outline-none border-[1px] border-[white] rounded-md' />
            <button className=  {`bg-[black] text-[white] py-2 px-5 my-2 rounded-md ${loading? "cursor-not-allowed" : "cursor-pointer"}`} disabled={loading} onClick={handleSubscription}>
              {
                loading? 'Loading...' : 'Subscribe'
              }
            </button>
          </div>
          <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-left text-gray-900 tracking-widest text-xl mb-3 capitalize">Categories</h2>
              <nav className="list-none mb-10 flex flex-col gap-3 text-left">
                <li className=''>
                  <NavLink to={`/categories/House and Garden`} className="text-gray-600 hover:text-gray-800 w-[100%] ">House & Garden</NavLink>
                </li>
                <li>
                  <NavLink to={`/categories/Sports`}  className="text-gray-600 hover:text-gray-800">Sports & Fitness</NavLink>
                </li>
                <li>
                  <NavLink to={`/categories/Pet Accessories`}  className="text-gray-600 hover:text-gray-800">Pet Accessories</NavLink>
                </li>
                <li>
                  <NavLink to='/categories/Gift and Flowers' className="text-gray-600 hover:text-gray-800">Gift & Flowers</NavLink>
                </li>
                <li>
                  <NavLink to={`/categories/Food & Drink`} className="text-gray-600 hover:text-gray-800">Food & Drink</NavLink>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-gray-900 tracking-widest text-xl mb-3 text-left">Information</h2>
              <nav className="list-none mb-10 flex flex-col gap-3 text-left">
                <NavLink to="/about">
                  <a className="text-gray-600 hover:text-gray-800">About</a>
                </NavLink>
                <NavLink to="/privacypolicy">
                  <a className="text-gray-600 hover:text-gray-800">Privacy & Policy</a>
                </NavLink>
                <NavLink to="/terms&conditions">
                  <a className="text-gray-600 hover:text-gray-800">Terms & Conditions</a>
                </NavLink>
                <li>
                  <NavLink to={"/How_do_i_redeem_a_voucher"} className="text-gray-600 hover:text-gray-800">How do i redeem a voucher?</NavLink>
                </li>
                <NavLink to="/contactus">
                  <a className="text-gray-600 hover:text-gray-800">Contact</a>
                </NavLink>
                {/* <li>
                  <a className="text-gray-600 hover:text-gray-800">Blogs</a>
                </li> */}
              </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className="title-font text-left font-medium text-gray-900 tracking-widest text-xl whitespace-nowrap mb-3">Stay Connected</h2>
              <nav className="list-none flex gap-4 text-left">
                <li>
                  <a
                   href='https://www.facebook.com/couponsdiscount.uk/'
                   target='_blank'
                  className="text-gray-600 hover:text-gray-800">
                    <FaFacebookF className='text-[22px] cursor-pointer' />
                  </a>
                </li>
                <li>
                  <a 
                  href='https://www.instagram.com/couponsdiscount.uk/'
                  target='_blank'
                  className="text-gray-600 hover:text-gray-800">
                    <FaInstagram className='text-[22px] cursor-pointer' />
                  </a>
                </li>
                <li>
                  <a
                   href='https://x.com/coupondiscountt'
                   target='_blank'
                  className="text-gray-600 hover:text-gray-800">
                    <RiTwitterXFill className='text-[22px] cursor-pointer' />
                  </a>
                </li>
                <li>
                  <a 
                  href='https://www.tiktok.com/@coupon_discount429?lang=en'
                  target='_blank'
                  className="text-gray-600 hover:text-gray-800">
                    <IoLogoTiktok className='text-[22px] cursor-pointer' />
                  </a>
                </li>
                <li>
                  <a
                  href='https://www.linkedin.com/in/couponsdiscount/'
                  target='_blank'
                  className="text-gray-600 hover:text-gray-800">
                    <FaLinkedinIn className='text-[22px] cursor-pointer' />
                  </a>
                </li>
              </nav>
              <div className='my-[20px]'>
                <h2 className="title-font text-left font-medium text-gray-900 tracking-widest text-sm mb-3">International</h2>
                <div className='flex gap-2'>
                  <a className="text-gray-600 hover:text-gray-800">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg" className='w-[40px] h-[40px] object-contain' alt="" />
                  </a>
                  <a className="text-gray-600 hover:text-gray-800">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg" className='w-[40px] h-[40px] object-contain' alt="" />
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>

      </footer>
    </div>
  )
}

export default Footer