import toast from "react-hot-toast"
import { CREATE_STORE_REQUEST, CREATE_STORE_REQUEST_ERROR, CREATE_STORE_REQUEST_FAIL, CREATE_STORE_REQUEST_SUCCESS } from "../constants/StoreConstant"
import { URL } from "../../setting/setting"




// --- create store admin 
export const CreateStore = (formdata , resetFormData)=> async(dispatch)=>{
    try {
        dispatch({type : CREATE_STORE_REQUEST})
        const res  = await fetch(`${URL}/store/create`,{
            method : "POST",
            headers : {
                "Content-Type": "application/json",
                token : localStorage.getItem("Utoken")
            },
            body : JSON.stringify(formdata)
         })
        dispatch({type : CREATE_STORE_REQUEST_FAIL})
        const data = await res.json()
        if(res.status === 400){
            return toast.error(data.message)
        }
        if(res.status === 500){
            return toast.error("internal server error")
        }
            dispatch({type : CREATE_STORE_REQUEST_SUCCESS, payload : data.stores})
           toast.success(data.message)
           resetFormData()

    } catch (error) {
        toast.error(error.message)
        dispatch({type : CREATE_STORE_REQUEST_ERROR})
    }
} 


// == get all shops 
export const GetStoreList = ()=> async(dispatch)=>{
    try {
        dispatch({type : CREATE_STORE_REQUEST})
        const res  = await fetch(`${URL}/store/all`,{
            method : "GET",
            headers : {
                "Content-Type": "application/json",
            },
         })
        dispatch({type : CREATE_STORE_REQUEST_FAIL})
        const data = await res.json()
        if(res.status === 400){
            return console.log(data.message)
        }
        if(res.status === 500){
            return toast.error("internal server error")
        }
            dispatch({type : CREATE_STORE_REQUEST_SUCCESS, payload : data.stores})
           console.log(data.message)

    } catch (error) {
        toast.error(error.message)
        dispatch({type : CREATE_STORE_REQUEST_ERROR})
    }
} 


// === delete store 
export const DeleteStore = (id)=> async(dispatch)=>{
    try {
        dispatch({type : CREATE_STORE_REQUEST})
        const res  = await fetch(`${URL}/store/delete/${id}`,{
            method : "DELETE",
            headers : {
                "Content-Type": "application/json",
                token : localStorage.getItem("Utoken")
            },
         })
        dispatch({type : CREATE_STORE_REQUEST_FAIL})
        const data = await res.json()
        if(res.status === 400){
            return toast.error(data.message)
        }
        if(res.status === 500){
            return toast.error("internal server error")
        }
            dispatch({type : CREATE_STORE_REQUEST_SUCCESS, payload : data.stores})
           toast.success(data.message)

    } catch (error) {
        toast.error(error.message)
        dispatch({type : CREATE_STORE_REQUEST_ERROR})
    }
} 


