import React, { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import "./Modal.css"

const Modal = ({setShowModal , showModal , item}) => {

    const [isCopy , setIsCopy] = useState(false)



    return (
        <div className="fixed z-50 inset-0 flex items-start mt-[30px] justify-center overflow-hidden">
            <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-primaryColor opacity-75"></div>
            </div>

            <div className="bg-[white] py-5 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full modal_div">
                <div className="bg-[white] px-2 pt-1   pb-4 sm:p-4 sm:pb-4">
                    <div className='flex justify-between items-center border-b-[1px] border-b-[#d1d1d177]  mb-5'>
                   <div className='flex justify-start gap-4 items-center'>
                   <img src={item?.store?.storeLogo?.url} alt="" 
                     className='w-[80px] h-[80px] object-contain'
                     />
                       <p className='text-[18] font-bold border-l-[1px] border-l-[#d1d1d177] pl-[4px]'>
                        {
                            // item?.freeShipping ? "Free shipping" : item?.discount ?  `Get ${item?.discount} dicsount` : `Get CouponCode`
                            item?.offerName
                        }
                       </p>
                   </div>
                    <RxCross1 className='text-[21px] cursor-pointer absolute top-2 right-2' onClick={()=> setShowModal(false)}/>
                     </div>
                    <div className="mt-2 relative">
                        <p className='w-[100%] py-[10px] px-[10px] text-[20px] font-bold bg-secondaryColor text-[white] rounded-md'>
                        {
                            //  item?.discount ?  `No need to code` : `${item?.CouponCode ? `${item?.CouponCode}` : "Free shipping"}`
                             item?.CouponCode ?  `${item?.CouponCode}` : `${item?.discount ? `No need to code` : "Free shipping"}`

                        }
                        {
                            item?.CouponCode &&
                            <button className='bg-[#DF4531] text-[16px] font-normal font-serif border-[1px] border-[#DF4531] rounded-r-md text-[white] px-[20px] h-[100%] absolute right-0 top-0' onClick={()=> {
                                navigator.clipboard.writeText(item?.CouponCode);
                                setIsCopy(true);
                                setTimeout(()=> setIsCopy(false), 3000);
                            }}>{isCopy ? "Copied" : "Copy and go"}</button>
                         } 
                        </p>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <a href={`${item?.affliateLink}`} target='_blank'>

                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                        Go to site
                    </button>
                        </a>
                </div>
            </div>
        </div>
    )
}

export default Modal