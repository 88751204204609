import React, { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import NewSpinner from '../../Components/spinner/NewSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { CreateOffer, UpdateOffer } from '../../redux/actions/OfferActions';
import toast from 'react-hot-toast';

const Modal = ({ setShowModal  , showModal , serIsEdit , Item , isEdit , setEditOffer }) => {
    const storeList = useSelector((state) => state.Store.StoreList);
    const categoryList = useSelector((state) => state.category.categoryList);
    const categoryLoading = useSelector((state) => state.category.categoryLoading);
    const storeLoading = useSelector((state) => state.Store.storeLoading);  
    const offerLoading = useSelector((state) => state.offer.offerLoading);  
    const dispatch = useDispatch();


    const [formData, setFormData] = useState({
        serialNo: isEdit ? Item?.serialNo : '',
        endDate:  isEdit ? Item?.endDate?.slice(0,10)  : '',
        // startDate: isEdit ? Item?.endtDate  : '',
        keyWords: isEdit ? Item?.keyWords  : '',
        metaTitle: isEdit ? Item?.metaTitle  : '',
        metaDescription: isEdit ? Item?.metaDescription  : '',
        metaLink: isEdit ? Item?.metaLink  :  '',
        affliateLink: isEdit ? Item?.metaLink  :  '',
        // offerType: isEdit ? Item?.offerType  :  '',
        offerName: isEdit ? Item?.offerName :  '',
        storeId: isEdit ? Item?.store?._id  : '',
        categoryId: isEdit ? Item?.category?._id : '',
        DiscountOffer : isEdit ? Item?.CouponCode ? "couponcode" : Item?.discount ? "discount" :  "freeShipping" :  "",
        discount : isEdit ? Item?.discount :"",
        CouponCode : isEdit ? Item?.CouponCode : "",
        freeShipping : isEdit ? Item?.reeShipping : false,
        id : Item?._id

    });

    const clearFormData = ()=>{
        setFormData({
            serialNo: "",
            endDate: "",
            startDate: '',
            keyWords: "",
            metaTitle: "",
            metaDescription: "",
            metaLink: "",
            affliateLink: "",
            // offerType: "",
            offerName: "",
            storeId: "",
            categoryId: "",
            DiscountOffer : "", ////
            discount : "",
            CouponCode : "",
            freeShipping :  false,
        })
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        if(updatedFormData.discount === "" && updatedFormData.CouponCode === ""){
            updatedFormData.freeShipping = true;
        }
        setFormData(updatedFormData);
    };


    const handleSubmit = async () => {
        if(formData?.metaDescription?.length > 200){
            toast.error("Meta Description should be less than 200 characters")
            return;
        }
        // Handle form submission
        if(isEdit){
            await dispatch(UpdateOffer(formData , clearFormData));
        }else{
            await dispatch(CreateOffer(formData , clearFormData));
        }

        
    };


   
    return (
        <div className="fixed h-[500px] overflow-y-auto  z-50 inset-0 flex items-start mt-[30px] justify-center overflow-hidden">
            <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-primaryColor opacity-75"></div>
            </div>

            <div className="bg-[white] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                <div className="bg-[white] px-2 pt-1 pb-4 sm:p-4 sm:pb-4">
                    <div className='flex justify-between items-center border-b-[1px] border-b-[#d1d1d177]'>
                        <div className='flex justify-start gap-4 items-center'>
                            <p className='text-[18px] font-bold border-l-[1px] border-l-[#d1d1d177] pl-[4px]'>Create New Offer</p>
                        </div>
                        <RxCross1 className='text-[21px] cursor-pointer' onClick={() => {
                            setShowModal(false)
                            serIsEdit(false)
                            setEditOffer({})
                        }} />
                    </div>
                    <div className="mt-2">
                    <div className='my-[10px]'>
                            <label>Discounts</label>
                            <select
                                name="DiscountOffer"
                                value={formData.DiscountOffer}
                                onChange={handleInputChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                                
                                        <option value="">Select discount options</option>
                                        <option value="couponcode">Coupon Code</option>
                                        <option value="discount">Discount</option>
                                        <option value="freeShipping">Free Shipping</option>
                                      
                            </select>
                        </div>

                        {
                             formData.DiscountOffer === "couponcode" &&
                             <div className='my-[10px]'>
                                <label>CouponCode</label>
                              <input
                              type={"text"}
                              name={"CouponCode"}
                              placeholder={`Please enter Coupon Code`}
                              value={formData["CouponCode"]}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          </div>
                        }
                        {
                             formData.DiscountOffer === "freeShipping" &&
                             <div className='my-[10px]'>
                                <label>CouponCode</label>
                              <input
                              type={"text"}
                              name={"freeShipping"}
                              placeholder={`freeShipping in true or false onl`}
                              value={formData["freeShipping"]}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          </div>
                        }
                        {
                             formData.DiscountOffer === "discount"  &&
                             <div className='my-[10px]'>
                                <label>Discount</label>
                              <input
                              type={"text"}
                              name={"discount"}
                              placeholder={`Please enter discount`}
                              value={formData["discount"]}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          </div>
                        }
                        {
                             formData.DiscountOffer === "couponcode" &&
                             <div className='my-[10px]'>
                                <label>Discount</label>
                              <input
                              type={"text"}
                              name={"discount"}
                              placeholder={`Please enter discount`}
                              value={formData["discount"]}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          </div>
                        }
                        {[
                           
                            
                      
                            { name: 'serialNo', label: 'Serial No' },
                            { name: 'endDate', label: 'Expire Date', type: 'date' },
                            // { name: 'startDate', label: 'Expire Date', type: 'date' },
                            { name: 'keyWords', label: 'Keywords' },
                            { name: 'metaTitle', label: 'Meta Title' },
                            { name: 'metaDescription', label: 'Meta Description' },
                            { name: 'metaLink', label: 'Meta Link' },
                            { name: 'affliateLink', label: 'Affiliate Link' },
                            // { name: 'offerType', label: 'Offer Type' },
                            { name: 'offerName', label: 'Offer Name' },
                            
                        ].map(({ name, label, type = 'text' }) => (
                            <div className='my-[10px]' key={name}>
                                <label>{label}</label>
                                <input
                                    type={type}
                                    name={name}
                                    placeholder={`Please enter ${label?.toLowerCase()}`}
                                    value={formData[name]}
                                    onChange={handleInputChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        ))}
                        <div className='my-[10px]'>
                            <label>Store Category</label>
                            <select
                                name="categoryId"
                                value={formData.categoryId}
                                onChange={handleInputChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                                {categoryLoading ? <NewSpinner /> : (
                                    <>
                                        <option value="">Select store category</option>
                                        {categoryList?.map((category) => (
                                            <option key={category?._id} value={category?._id}>{category?.CategoryName}</option>
                                        ))}
                                    </>
                                )}
                            </select>
                        </div>
                      
                        <div className='my-[10px]'>
                            <label>Store</label>
                            <select
                                name="storeId"
                                value={formData.storeId}
                                onChange={handleInputChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                                {storeLoading ? <NewSpinner /> : (
                                    <>
                                        <option value="">Select store</option>
                                        {storeList?.map((store) => (
                                            <option key={store?._id} value={store?._id}>{store?.storeName}</option>
                                        ))}
                                    </>
                                )}
                            </select>
                        </div>
                      
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-1 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className={`${offerLoading && "cursor-no-drop"} w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm`}
                        disabled={offerLoading}
                    >
                        {offerLoading ? <NewSpinner /> : isEdit ? "Update" : "Create"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
