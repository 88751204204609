import { createReducer } from "@reduxjs/toolkit";
import { CREATE_STORE_REQUEST, CREATE_STORE_REQUEST_ERROR, CREATE_STORE_REQUEST_FAIL, CREATE_STORE_REQUEST_SUCCESS } from "../constants/StoreConstant";


const initialState = {
    storeLoading: false,
    StoreList : [],
}

export const StoreReducer = createReducer(initialState , (builder)=>{
    builder
    .addCase(CREATE_STORE_REQUEST ,(state)=>{
        state.storeLoading = true;
    })
    .addCase(CREATE_STORE_REQUEST_FAIL ,(state)=>{
        state.storeLoading = false;
    })
    .addCase(CREATE_STORE_REQUEST_SUCCESS , (state,action)=>{
        state.storeLoading = false;
        state.StoreList = action.payload;
    })
    .addCase(CREATE_STORE_REQUEST_ERROR, (state)=>{
        state.storeLoading = false;
    })
})