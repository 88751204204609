import React from 'react'

const About = () => {

    const data = [
        {
            heading : "Top Fashion and Lifestyle Trends.",
             text : "Discover a vast array of fashion, home, and lifestyle products from leading brands on shopping.de. Explore ourcurated collection for inspiration or easily find your favorite items with our user-friendly search feature!."
        },
        {
            heading : "Discover your perfect item with just a few clicks.",
             text : "Use our filters to quickly locate items across fashion, handbags, shoes, accessories, and home furnishings. Once you have found what you are looking for, seamlessly transition to the partner shop's website to complete your purchase."
        },
        {
            heading : "Shop safely.",
             text : "We prioritize online shopping security by rigorously vetting our partner shops to meet quality standards, ensuring  your safety while you shop."
        },
        {
            heading : "Optimal discounts.",
             text : "Maximize savings with discount coupons, online codes, and auction sites offering prices below market rates. Online codes yield discounts ranging from 5% to 80% or €5 to €100 off. Some stores discount up to 90% or offer reductions for purchases exceeding certain thresholds, such as €50 or €100, granting customers 20% off or €20 discount."
        },
        {
            heading : "Explore our diverse array of deals.",
             text : "With ShoppingSpot, saving is effortless. Access a multitude of discounts from leading UK code websites and top coupon platforms. Say farewell to full-price purchases and embrace unbeatable savings on your preferred brands and items. Unlock exclusive offers with ease and enjoy significant discounts on all your favorites, all with just a few clicks."
        },

    ]




    return (
        <div className='max-w-[2000px] 2xl:px-[150px] px-[10px] md:px-[50px]  my-[50px] py-[20px] text-primaryColor'>
            <section className="text-gray-600 body-font ">
                <div className="container px-5 py-24 mx-auto" >
                    <div className="flex flex-wrap -m-4" >
              {
                data?.map((item,index)=>{
                    return(
                            <div className="p-4 md:w-1/3" key={index}>
                                <div className="h-full border-[1px] border-[#F2F3F4] border-opacity-[.5] rounded-lg overflow-hidden">
                                    {/* <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://dummyimage.com/720x400" alt="blog"/> */}
                                    <div className="p-6">
                                        {/* <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2> */}
                                        <h1 className="title-font text-[23px] text-left font-medium text-gray-900 mb-3 capitalize">{item?.heading}</h1>
                                        <p className="text-[gray] opacity-[.8] leading-relaxed mb-3 text-left">{item?.text}</p>
                                    </div>
                                </div>
                            </div>
                    )
                })
            }
            </div>
            </div>
            </section>

        </div>
    )
}

export default About