import { createReducer } from "@reduxjs/toolkit";
import { CREATE_OFFER_REQUEST, CREATE_OFFER_REQUEST_ERROR, CREATE_OFFER_REQUEST_FAIL, CREATE_OFFER_REQUEST_SUCCESS } from "../constants/OfferConstant";


const initialState = {
    offerLoading: false,
    offerList : [],
}

export const OfferReducer = createReducer(initialState , (builder)=>{
    builder
    .addCase(CREATE_OFFER_REQUEST ,(state)=>{
        state.offerLoading = true;
    })
    .addCase(CREATE_OFFER_REQUEST_FAIL ,(state)=>{
        state.offerLoading = false;
    })
    .addCase(CREATE_OFFER_REQUEST_SUCCESS , (state,action)=>{
        state.offerLoading = false;
        state.offerList = action.payload;
    })
    .addCase(CREATE_OFFER_REQUEST_ERROR, (state)=>{
        state.offerLoading = false;
    })
})