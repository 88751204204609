import { configureStore } from "@reduxjs/toolkit";
import { UserReducer } from "./reducers/UserReducer";
import { StoreReducer } from "./reducers/StoreReducer";
import { CategoryReducer } from "./reducers/CtaegoryReducer";
import { OfferReducer } from "./reducers/OfferReducer";



export const Store  = configureStore({
    reducer:{
     user : UserReducer,
     Store : StoreReducer,
     category : CategoryReducer,
     offer : OfferReducer
    }
});