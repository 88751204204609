import React, { useEffect } from 'react'
import Navbar from './Components/Layout/Navbar'
import Footer from './Components/Layout/Footer';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from './Pages/Home';
import Shop from './Pages/Shop';
import AllShops from './Pages/AllShops';
import AllCategories from './Pages/AllCategories';
import Category from './Pages/Category';
import Dashboard from './Dashboard/Dashboard';
import Login from './Pages/Login';
import  toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { VerifyUser } from './redux/actions/UserActions';
import About from './Pages/About';
import { ScrollToTop } from "react-router-scroll-to-top"
import PrivacyPolicy from './Pages/Privicay';
import Conditions from './Pages/Conditions';
import Contactus from './Pages/Contactus';
import Redeem from './Pages/Redeem';

const App = () => {

  const dispatch = useDispatch();
  const token = localStorage.getItem("Utoken")


  useEffect(()=>{
     if(token){
      // @ts-ignore
      dispatch(VerifyUser())
     }
  },[])



  return (
    <BrowserRouter>
    <ScrollToTop  />
     <Navbar/>
    <Toaster position='top-right' containerStyle={{zIndex : "99999999999"}}/>
     <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/shop/:name' element={<Shop/>}/>
      <Route path='/shops' element={<AllShops/>}/>
      <Route path='/categories' element={<AllCategories/>}/>
      <Route path='/categories/:name' element={<Category/>}/>
      {/* --- dashboard */}
      <Route path='/dashboard' element={<Dashboard/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/about' element={<About/>}/>    
      <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>    
      <Route path='/terms&conditions' element={<Conditions/>}/>    
      <Route path='/contactus' element={<Contactus/>}/> 
      <Route path='/How_do_i_redeem_a_voucher' element={<Redeem/>}/> 

     </Routes>
     <Footer/>    
    </BrowserRouter>
  )
}

export default App
