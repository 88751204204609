import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { URL } from '../setting/setting';

const Contactus = () => {


    const [name , setName] = useState("");
    const [email , setemail] = useState("")
    const [message , setmessage] = useState("");
    const [loading , setloading] = useState("")



    const handleSubmit = async ()=>{

        if(!name || !email || !message){
            return toast.error("All required fields")
        };

        // do api call
        try {
            setloading(true)
            var toastID = toast.loading("Loading please wait...")
            const res  = await fetch(`${URL}/contact/send/contact/mail`,{
                method : "POST",
                headers : {
                    "Content-Type": "application/json",
                },
                body : JSON.stringify({
                    name,
                    email,
                    message
                })
    
            })
            setloading(false)
            const data = await res.json()
            if(res.status === 400){
                toast.error(data.message , {id : toastID})
                return toast.error(data.message)
            }
            if(res.status === 500){
                toast.error("internal server error" , {id : toastID})
                return toast.error("internal server error")
            }
                toast.success(data.message, {id : toastID})
                setName("")
                setemail("")
                setmessage("")
            
    
        } catch (error) {
            toast.error(error.message, {id : toastID})
            setloading(false)
        }

    }




    return (
        <div className='max-w-[2000px] mx-auto'>
            <section className="text-gray-600 body-font relative">
                <div className="container  px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-12">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Contact Us</h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                            Here you can leave your questions in the form below and contact us if you need any help related to the website, we will get back to you as soon as possible.
                        </p>
                    </div>
                    <div className="lg:w-1/2 md:w-2/3 mx-auto">
                        <div className="flex flex-wrap -m-2">
                            <div className="p-2 w-1/2">
                                <div className="relative">
                                    <label for="name" className="leading-7 text-sm text-gray-600">Name</label>
                                    <input
                                    value={name}
                                    onChange={(e)=> setName(e.target.value)}
                                     type="text" id="name" name="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            <div className="p-2 w-1/2">
                                <div className="relative">
                                    <label for="email" className="leading-7 text-sm text-gray-600">Email</label>
                                    <input 
                                     value={email}
                                     onChange={(e)=> setemail(e.target.value)}
                                    type="email" id="email" name="email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative">
                                    <label for="message" className="leading-7 text-sm text-gray-600">Message</label>
                                    <textarea
                                     value={message}
                                     onChange={(e)=> setmessage(e.target.value)}
                                    id="message" name="message" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                                </div>
                            </div>
                            <div className="p-2 w-full ">
                                <button
                                disabled={loading}
                                onClick={handleSubmit}
                                className={`bg-[black] ${loading && "cursor-not-allowed"} w-[100%] text-center text-[white] mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg`}>Button</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="p-2 w-full text-center mb-8">
                    <p className='text-[20px] font-medium my-3'>Based in: UK</p>
                    <p className='text-[20px] font-medium my-3'>VAT registration number: UK 15868822</p>
                    <p className='text-[20px] font-medium my-3'>Email: Affiliatespot429@gmail.com</p>
                    <p className='text-[20px] font-medium my-3'>Phone: UK +442076085500</p>
                </div>
            </section>
        </div>
    )
}

export default Contactus