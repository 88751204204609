import React, { useState } from "react";
import { LeftNavbar } from "reactui-circle";
import { FiSearch } from "react-icons/fi";
import "./styles/Navbar.css";
import { RxCross2 } from "react-icons/rx";
import { IoMenuSharp } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo3.jpg";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      navigate(`/shops?isSearch=${true}&value=${search}`);
      setShowNavbar(false);
    }
  };

  return (
    <div className="navbar_parent max-w-[2000px] !z-[1000000000000000] 2xl:px-[150px] px-[50px] border-b-[1px] border-[#ddddddc9] bg-[white] py-[17px] text-primaryColor">
      <div className="Mobile_Navbar w-[100%]  !z-[0999999999999] ">
        <div className="w-[100%] flex justify-between place-items-center">
          <NavLink to="/">
            {/* <h1 className="text-[25px] font-bold">
            Coupons<span className="text-secondaryColor">Discount</span>
            </h1> */}
            <img src={Logo} alt="" className="h-[60px] object-contain w-[200px]"/>
          </NavLink>
          <IoMenuSharp
            className="text-primaryColor text-[27px] cursor-pointer cross mt-[-7px]"
            onClick={() => setShowNavbar(true)}
          />
        </div>
      </div>
      <LeftNavbar showNavbar={showNavbar}>
        <div className="Navbar flex justify-between place-items-center gap-5 9]">
          {/* --- left snavbar  */}
          <div className="left_navbar flex justify-between place-items-center gap-[100px] w-[100%]">
            <div className="heading_p w-[30%]">
              <NavLink to="/">
                {/* <h1 className="text-[30px] font-bold">
                Coupons<span className="text-secondaryColor">Discount</span>
                </h1> */}
                <img src={Logo} alt="" className="h-[50px] w-[100%] object-contain"/>
              </NavLink>
              <RxCross2
                className="text-primaryColor text-[27px] cursor-pointer cross mt-[-7px]"
                onClick={() => setShowNavbar(false)}
              />
            </div>
            {/* ----- menus  */}
            <ul className="flex justify-center place-items-center gap-3">
              <NavLink
                to="/"
                className="w-[100%]"
                onClick={() => setShowNavbar(false)}
              >
                <li>Home</li>
              </NavLink>
              <NavLink
                to="/shops"
                className="w-[100%]"
                onClick={() => setShowNavbar(false)}
              >
                <li>Shops</li>
              </NavLink>
              <NavLink
                to="/categories"
                className="w-[100%]"
                onClick={() => setShowNavbar(false)}
              >
                <li className="whitespace-nowrap">Categories</li>
              </NavLink>
            </ul>
          </div>
          {/* --- right navbar  */}
          <div className="w-[80%] right_p  flex justify-end place-items-center">
            <div className="right_navbar rounded-md  flex justify-start place-items-center w-[70%] border-[1px] border-primaryColor">
              <input
                type="text"
                placeholder="Search for shop"
                onKeyDown={handleKeyDown}
                value={search}
                onChange={handleSearch}
              />
              <div className="border-2  py-[8px] min-w-[40px] w-[10%] flex justify-center place-items-center bg-primaryColor cursor-pointer">
                <FiSearch
                  className="text-[white] text-[23px]"
                  onClick={() => {
                    navigate(`/shops?isSearch=${true}&value=${search}`);
                    setShowNavbar(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </LeftNavbar>
    </div>
  );
};

export default Navbar;
