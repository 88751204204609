import toast from "react-hot-toast"
import { USER_CREATE_REQUEST, USER_CREATE_REQUEST_ERROR, USER_CREATE_REQUEST_FAIL, USER_CREATE_REQUEST_SUCCESS } from "../constants/UserConstant"
import { URL } from "../../setting/setting"



export const LoginUser = (email , password)=> async (dispatch)=>{
    try {
        dispatch({type : USER_CREATE_REQUEST})
        const res = await fetch(`${URL}/user/login`, {
            method : "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body : JSON.stringify({
                email,
                password
            })
        });
        dispatch({type : USER_CREATE_REQUEST_FAIL})
        const data = await res.json()
        if(res.status === 400){
            return toast.error(data.message)
        }
        if(res.status === 500){
            return toast.error("Internal Server Error")
        }
        if(res.status === 200){
            dispatch({type : USER_CREATE_REQUEST_SUCCESS, payload : data.user})
            localStorage.setItem("Utoken", data.token)
             toast.success(data.message)
        }
        
    } catch (error) {
        toast.error(error.message)
        dispatch({type : USER_CREATE_REQUEST_ERROR})
    }
}


// ---- verify user
export const VerifyUser = ()=> async (dispatch)=>{
    try {
        dispatch({type : USER_CREATE_REQUEST})
        const res = await fetch(`${URL}/user/me`, {
            method : "POST",
            headers: {
                "Content-Type": "application/json",
                token : localStorage.getItem("Utoken")
            },
           
        });
        dispatch({type : USER_CREATE_REQUEST_FAIL})
        const data = await res.json()
        if(res.status === 400){
            return console.log(data.message)
        }
        if(res.status === 500){
            return toast.error("Internal Server Error")
        }
        if(res.status === 200){
            dispatch({type : USER_CREATE_REQUEST_SUCCESS, payload : data.user})
             console.log(data.message)
        }
        
    } catch (error) {
        console.log(error.message)
        dispatch({type : USER_CREATE_REQUEST_ERROR})
    }
}





export const NewsLetter = (email )=> async (dispatch)=>{
    try {
        dispatch({type : USER_CREATE_REQUEST})
        const res = await fetch(`${URL}/user/newsletter`, {
            method : "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body : JSON.stringify({
                email,
            })
        });
        dispatch({type : USER_CREATE_REQUEST_FAIL})
        const data = await res.json()
        if(res.status === 400){
            return toast.error(data.message)
        }
        if(res.status === 500){
            return toast.error("Internal Server Error")
        }
        if(res.status === 200){
            dispatch({type : USER_CREATE_REQUEST_SUCCESS, payload : data.user})
             toast.success(data.message)
        }
        
    } catch (error) {
        toast.error(error.message)
        dispatch({type : USER_CREATE_REQUEST_ERROR})
    }
}