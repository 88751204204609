import React, { useEffect, useState } from 'react'
import Loading from '../spinner/Loading'
import { useDispatch, useSelector } from 'react-redux';
import { GetAllOffer } from '../../redux/actions/OfferActions';
import Modal from '../Modal/Modal';

const Brands = () => {
  const [showModal, setShowModal] = useState(false)
  const [item, setItem] = useState(null)
  const offerList = useSelector((state: any) => state.offer.offerList?.slice(-30));
  const offerLoadng = useSelector((state: any) => state.offer.offerList?.offerLoading);



  const dispatch = useDispatch()
  useEffect(() => {
    if (offerList?.length === 0) {
      // @ts-ignore
      dispatch(GetAllOffer())
    }
  }, [])



  return (
    <div className='max-w-[2000px] 2xl:px-[150px] px-[10px] md:px-[50px] my-[10px] py-[20px] text-primaryColor'>
      <h1 className='text-center md:text-[30px] text-[23px] my-[20px] capitalize'>The best promotions of this months</h1>
      {
        offerLoadng ?
          <Loading />
          :
          <div className='flex justify-center place-items-center gap-[20px] flex-wrap mt-5 '>
            {offerList && offerList?.length > 0 ?
              offerList?.map((item: any, index: any) => {
                return (
                  <div onClick={() => {
                    setShowModal(true)
                    setItem(item)
                  }} className='flex flex-col justify-between gap-[10px] place-items-center w-[45%] md:w-[260px] overflow-hidden md:flex-shrink-0 h-[180px]  rounded-md hover:shadow-lg cursor-pointer border-[1px] border-[#e4e3e3b2]' key={index}>
                    <div className='h-[100%] flex flex-col justify-center gap-2 px-2'>
                      <img src={item?.store?.storeLogo?.url} alt="" className='w-[80px] mx-auto  h-[30px] object-contain rounded-md' />
                      <h2 className='text-[14px] text-center'>{item?.offerName?.slice(0, 70)}</h2>
                    </div>
                    <p className={`font-bold w-[100%]  ${item?.CouponCode ? "bg-[#DF4531]" : "bg-[#4D589F]"} text-[white] py-[10px] text-center border-t-[1px] border-t-[#e4e3e3b2] text-[14px]`}>{item?.storeName}
                      {item?.CouponCode ? "Show Code" : "Show Offer"}
                    </p>
                  </div>
                )
              })
              : <p className='w-[100%] text-center text-[18px]'>No Store List Found</p>
            }
          </div>
      }

      {/* =========== modal  */}
      {showModal && (
        <div className={""}>
          {
            <Modal
              setShowModal={setShowModal}
              showModal={showModal}
              item={item}
            />
          }
        </div>
      )}

    </div>
  )
}

export default Brands