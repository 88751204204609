import React, { Dispatch, SetStateAction } from 'react';
import "./Sidebar.css"
import { NavLink, useNavigate } from 'react-router-dom';

const Sidebar = ({setActive}:any) => {
    const navigation = useNavigate()
  return (
    <div className='min-w-[320px] h-[100%]  py-[30px] px-[20px] bashboard_sidebar '>
        <ul>
            <NavLink to="/">
            <li>
                Home
            </li>
            </NavLink>
            <li onClick={()=> setActive(0)}>
             Stores
            </li>
            <li onClick={()=> setActive(1)}>
                Categories
            </li>
            <li onClick={()=> setActive(2)}>
                Offers
            </li>
            <li onClick={()=> {
                localStorage.removeItem("Utoken");
                navigation("/login")
            }}>
                Logout
            </li>
        </ul>
    </div>
  )
}

export default Sidebar