import { createReducer } from "@reduxjs/toolkit";
import { USER_CREATE_REQUEST, USER_CREATE_REQUEST_ERROR, USER_CREATE_REQUEST_FAIL, USER_CREATE_REQUEST_SUCCESS } from "../constants/UserConstant";


const initialState = {
    UserLoading: false,
    User: {},
}

export const UserReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(USER_CREATE_REQUEST, (state) => {
            state.storeLoading = true;
        })
        .addCase(USER_CREATE_REQUEST_FAIL, (state) => {
            state.storeLoading = false;
        })
        .addCase(USER_CREATE_REQUEST_SUCCESS, (state, action) => {
            state.storeLoading = false;
            state.User = action.payload;
        })
        .addCase(USER_CREATE_REQUEST_ERROR, (state) => {
            state.storeLoading = false;
        })
})