import React, { useEffect } from 'react'
import Hero from '../Components/Layout/Hero/Hero'
import Brands from '../Components/Brands/Brands'
import Categories from '../Components/Categories/Categories'
import Shops from '../Components/Shops/Shops'
import About from '../Components/About/About'
import { useDispatch, useSelector } from 'react-redux'
import { GetStoreList } from '../redux/actions/StoreActions'
import { GetAllCategory } from '../redux/actions/categoryActions';
import "./styles/home.css"
import { NavLink } from 'react-router-dom'
import { Helmet } from "react-helmet";


const Home = () => {
    const StoreList = useSelector((state: any) => state.Store.StoreList?.slice());
    const categoryList = useSelector((state:any)=> state.category.categoryList)
    const storeLoading = useSelector((state: any) => state.Store.storeLoading);
    const dispatch = useDispatch();
    useEffect(() => {
        const fetch = async () => {
            if(StoreList?.length === 0){
                // @ts-ignore
                await dispatch(GetStoreList())
            }
            if(categoryList?.length === 0){
                // @ts-ignore
                await dispatch(GetAllCategory())
            }
        }
        fetch()
    }, []);


    return (
        <div className='max-w-[2000px] mx-auto'>
             <Helmet>
                <meta charSet="utf-8" />
                <title>
                  {"couponsdiscount"}
                </title>
                <meta name="description" content={"CouponsDiscount is a tool designed to help users find and apply promotional codes and discounts for various online stores. It aggregates coupon codes from different retailers, making it easy to save money on purchases. With a user-friendly interface, it simplifies the process of finding the best deals and discounts available."} />
              </Helmet>
            <Hero />
         <div className='home_grid md:mx-[50px] py-[20px]'>
                <div>
                    <Brands />
                </div>
                <div className='border-[1px]  bg-[#f2f3f4] py-[0px] px-[10px] border-[#F2F3F4] rounded-md max-h-[750px]'>
                    <div className='mt-5'>
                        <h1 className='mb-2 text-[20px] font-medium capitalize'>Popular shops</h1>
                        <div className=' p-[10px] rounded-md sidebar_shops'>
                            {
                               StoreList && StoreList?.slice(-10)?.map((item: any, index: any) => {
                                    return (
                                        <NavLink to={`/shop/${item?.storeName}`} className=' p-2 justify-start  gap-[10px] place-items-start w-[100%]  flex-shrink-0 h-[110px] rounded-lg hover:shadow-lg cursor-pointer border-[1px] border-[white]' key={index}>
                                            <div className='w-[100%] relative  h-[100%] flex gap-1 flex-col justify-center place-items-center'>
                                                <img src={item?.storeLogo?.url} alt="" className='w-[100px] h-[90px]  object-contain rounded-md overflow-hidden' />
                                            <p>{item?.offers ? item?.offers?.length : "0"} Offers</p>
                                            </div>

                                        </NavLink>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Categories />

            <Shops />

            <About /> 
        </div>
    )
}

export default Home