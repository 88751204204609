import React, { useEffect, useState } from 'react';
import "./Dashboard.css"
import Sidebar from './Sidebar/Sidebar';
import Shops from './Shops/Shops';
import Categories from './Categories/Categories';
import Offers from './offers/Offers';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [active, setActive] = useState(0)
    const dispatch = useDispatch()
    const User = useSelector((state: any) => state.user.User);
   const navigate = useNavigate()
    const token = localStorage.getItem("Utoken")
    useEffect(() => {
        if (token && !User) {
            // @ts-ignore
            dispatch(VerifyUser())
        }
    }, [User])

    useEffect(() => {
        if (User && User.role !== "admin") {
            if(!token){
                // @ts-ignore
                navigate("/login");
            }
        }
    }, [User])
    return (
        <div className='fixed  top-0 left-0 w-[100%] overflow-y-auto h-[100%] bg-[white] z-[9999999999999] dashboard'>
            <Sidebar setActive={setActive} />
            <div className='w-[100%] px-[20px]'>
                {
                    active === 0 && <Shops />
                }
                {
                    active === 1 && <Categories />
                }
                {
                    active === 2 && <Offers />
                }
            </div>
        </div>
    )
}

export default Dashboard