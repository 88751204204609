import React, { useEffect, useState } from 'react';
import "./Shops.css"
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Modal from './Modal.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllCategory } from '../../redux/actions/categoryActions';
import { DeleteStore, GetStoreList } from '../../redux/actions/StoreActions';
import NewSpinner from '../../Components/spinner/NewSpinner';


const Shops = () => {
    const [showModal , setShowModal] = useState(false);
    const categoryList = useSelector((state:any)=> state.category.categoryList);
    const StoreList = useSelector((state:any)=> state.Store.StoreList);
    const storeLoading = useSelector((state:any)=> state.Store.storeLoading);
    const dispatch = useDispatch()
    useEffect(()=>{
        if(categoryList && categoryList?.length === 0){
            // @ts-ignore
            dispatch(GetAllCategory())
        }
        // @ts-ignore
        dispatch(GetStoreList())
    },[])

    return (
        <div className='w-[100%] d_shops'>
            <div className='w-[100%] my-[20px] py-3 flex justify-between items-center'>
                <h2 className='text-[35px] font-medium'>{StoreList?.length} Stores.</h2>
                <button onClick={()=> setShowModal(true)} className='bg-primaryColor text-[white] px-[20px] py-[9px] rounded-md'>+ add new store</button>
            </div>

            {/* --- table  */}
           <div className='h-[400px] overflow-y-auto'>
           <table className=' w-[100%]'>
                <thead className='w-[100%]  bg-secondaryColor'>
                    <tr className=' w-[100%]  text-[white]'>
                        <th className=''>Store Name</th>
                        <th className=''>Network</th>
                        <th className=''>Meta title</th>
                        <th className=''>Meta description</th>
                        <th className=''>Store Category</th>
                        <th className=''>Actions</th>
                    </tr>
                </thead>
                {/* -- bod y */}
                {
                    storeLoading ? <div className='my-2'>
                        <NewSpinner/>
                    </div>
                    :
                    <tbody>
                    {
                        StoreList && StoreList?.map((item:any, index:any) => {
                            return (
                                <tr className='bg-[#eeeeee] border-b-[1px] border-b-primaryColor'>
                                    <td className='flex justify-start gap-2 items-center'>
                                        {item?.storeName}
                                        <img className='w-[40px] h-[40px] rounded-full' src={
                                            item?.storeLogo?.url
                                        } alt="" />
                                    </td>
                                    <td className=''>
                                        {item?.network}
                                    </td>
                                    <td className=''>
                                        {item?.metaTitle}
                                    </td>
                                    <td className='max-w-[300px]'>
                                        {item?.metaDescription?.slice(0,20)}...
                                    </td>
                                    <td className=''>
                                        {item?.storeCategory?.CategoryName}
                                    </td>
                                    <td className='flex justify-center items-center gap-2'>
                                        <MdDeleteOutline className='text-[23px] text-[red] cursor-pointer'
                                        onClick={()=>{
                                            // @ts-ignore
                                            dispatch(DeleteStore(item?._id))
                                        }}
                                        />
                                        {/* <FaEdit  className='text-[23px] cursor-pointer text-[green]'/> */}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                }
              

            </table>
           </div>
           {
            showModal &&
            <Modal setShowModal={setShowModal}  showModal={showModal} />
           }
        </div>
    )
}

export default Shops