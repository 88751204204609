import React, { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import NewSpinner from '../../Components/spinner/NewSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { CreateStore } from '../../redux/actions/StoreActions';

const Modal = ({ setShowModal, showModal }) => {

    const categoryList = useSelector((state)=> state.category.categoryList)
    const categoryLoading = useSelector((state)=> state.category.categoryLoading)
    const storeLoading = useSelector((state)=> state.Store.storeLoading)
   const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        storeName: '',
        network: '',
        metaTitle: '',
        metaDescription: '',
        storeCategory: '',
        storeLogo: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({ ...formData, storeLogo: reader.result });
            };
            reader.readAsDataURL(file);
        }
    };

    const resetFormData = ()=>{
        setFormData({
            storeName: '',
            network: '',
            metaTitle: '',
            metaDescription: '',
            storeCategory: '',
            storeLogo: ''
        });   
    }

    const handleSubmit = () => {
        // Handle form submission
      dispatch(CreateStore(formData , resetFormData))
    };

    return (
        <div className="fixed z-50 inset-0 flex items-start mt-[30px] justify-center overflow-hidden">
            <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-primaryColor opacity-75"></div>
            </div>

            <div className="bg-[white] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                <div className="bg-[white] px-2 pt-1 pb-4 sm:p-4 sm:pb-4">
                    <div className='flex justify-between items-center border-b-[1px] border-b-[#d1d1d177]'>
                        <div className='flex justify-start gap-4 items-center'>
                            <p className='text-[18px] font-bold border-l-[1px] border-l-[#d1d1d177] pl-[4px]'>Create New Store</p>
                        </div>
                        <RxCross1 className='text-[21px] cursor-pointer' onClick={() => setShowModal(false)} />
                    </div>
                    <div className="mt-2">
                        <div className='my-[10px]'>
                            <label>Store Name</label>
                            <input
                                type="text"
                                name="storeName"
                                placeholder='Please enter store name'
                                value={formData.storeName}
                                onChange={handleInputChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <div className='my-[10px]'>
                            <label>Store Network</label>
                            <input
                                type="text"
                                name="network"
                                placeholder='Please enter store network'
                                value={formData.network}
                                onChange={handleInputChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <div className='my-[10px]'>
                            <label>Store Meta Title</label>
                            <input
                                type="text"
                                name="metaTitle"
                                placeholder='Please enter store metaTitle'
                                value={formData.metaTitle}
                                onChange={handleInputChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <div className='my-[10px]'>
                            <label>Store Meta Description</label>
                            <input
                                type="text"
                                name="metaDescription"
                                placeholder='Please enter store metaDescription'
                                value={formData.metaDescription}
                                onChange={handleInputChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <div className='my-[10px]'>
                            <label>Store Category</label>
                            <select
                                name="storeCategory"
                                value={formData.storeCategory}
                                onChange={handleInputChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                                {
                                    categoryLoading ? <NewSpinner/>
                                     : 
                                    <>
                                     <option value="">Select store category</option>
                                    {
                                        categoryList && categoryList?.map((category) => (
                                            <option value={category?._id}>{category?.CategoryName}</option>
                                        ))
                                    }
                                    </>

                                }
                           
                            </select>
                        </div>
                        <div className='my-[10px]'>
                            <label>Store Image</label>
                            <input
                                type="file"
                                name="storeLogo"
                                onChange={handleFileChange}
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-1 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className={`${storeLoading && "cursor-no-drop"} w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm`}
                        disabled={storeLoading}
                    >
                        {
                            storeLoading ? <NewSpinner/> :
                            "create"
                        }
                        
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
