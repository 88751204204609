import { createReducer } from "@reduxjs/toolkit";
import { CREATE_CATEGORY_REQUEST, CREATE_CATEGORY_REQUEST_ERROR, CREATE_CATEGORY_REQUEST_FAIL, CREATE_CATEGORY_REQUEST_SUCCESS } from "../constants/CategoryConstant";


const initialState = {
    categoryLoading: false,
    categoryList : [],
}

export const CategoryReducer = createReducer(initialState , (builder)=>{
    builder
    .addCase(CREATE_CATEGORY_REQUEST ,(state)=>{
        state.categoryLoading = true;
    })
    .addCase(CREATE_CATEGORY_REQUEST_FAIL ,(state)=>{
        state.categoryLoading = false;
    })
    .addCase(CREATE_CATEGORY_REQUEST_SUCCESS , (state,action)=>{
        state.categoryLoading = false;
        state.categoryList = action.payload;
    })
    .addCase(CREATE_CATEGORY_REQUEST_ERROR, (state)=>{
        state.categoryLoading = false;
    })
})