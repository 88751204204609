import React from "react";
import { Helmet } from "react-helmet";

const OfferpageCompo = ({
  NewofferList,
  expiredOfferList,
  setShowModal,
  setItem,
}) => {

  return (
    <div className="flex flex-col justify-start place-items-start gap-[20px] flex-wrap mt-5">
      <h2 className="mx-2 font-bold text-xl">Active offers</h2>
      {NewofferList && NewofferList?.length > 0 ? (
        NewofferList?.map((item, index) => {
          return (
            <>
           <Helmet>
                <meta charSet="utf-8" />
                <title>
                  {NewofferList && NewofferList[0]?.store?.metaTitle}
                </title>
                <meta name="description" content={item?.store?.metaDescription || "Offers and discounts available"} />
                <meta name="keywords" content={item?.keyWords  || "Offer code , CouponCode"} />

              </Helmet>

            <div
              className="flex  justify-start bg-[white] gap-[10px] place-items-start w-[100%]  flex-shrink-0 min-h-[150px] h-auto  rounded-lg hover:shadow-lg cursor-pointer border-[1px] border-[#e4e3e3b2] shop_offer_list"
              key={index}
            >
              <div className="w-[200px]  relative border-[1px] border-[#F2F3F4] flex-shrink-0 h-[100%] flex justify-between flex-col shop_offer_list_image_box">
                {/* <img src={item?.store?.storeLogo.url} alt="" className='w-[200px] h-[100px] object-contain' /> */}
                <div className="w-[200px] h-[100%] flex justify-center place-items-center image_box_content_div">
                  <p className="font-bold text-[22px] text-[#3F5BA9]">
                    {item?.CouponCode
                      ? `${item?.discount}`
                      : item?.discount
                      ? item?.discount
                      : "FreeShpping"}
                  </p>
                </div>
                <p className="bg-primaryColor text-[white] text-center py-1">
                  {item?.CouponCode
                    ? `Coupon`
                    : item?.discount
                    ? "Discount"
                    : "FreeShpping"}{" "}
                </p>
                {/* {
                                                            item?.discount &&
                                                            <span className="absolute top-0 right-[-15px] z-50 bg-[#E1452F] text-[white] rotate-[20deg] inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                                                                {item?.discount}
                                                            </span>
                                                        } */}
              </div>
              <div className="py-[12px] px-[7px]">
                <h2 className=" w-[100%] py-[10px] text-left font-bold text-[16px]">
                  {item?.offerName?.length > 90
                    ? `${item?.offerName?.slice(0, 90)}...`
                    : item?.offerName}
                </h2>
                <p className="text-[14px] text-[gray] max-w-[100%]">
                  {item?.metaDescription?.slice(0, 180)}...
                </p>

                <div className="w-[100%] flex justify-between place-items-center">
                  <p style={{ fontStyle: "italic" }} className="mt-[7px]">
                    <span className="font-medium mr-2">Expire on</span>{" "}
                    <span className="text-[gray]">
                      {item?.endDate?.slice(0, 10)}
                    </span>
                  </p>

                  <button
                    className={`${item?.CouponCode ? "bg-[#DF4531]" : "bg-[#4D589F]"} mt-[13px] text-[white] py-[8px] px-[50px] cursor-pointer rounded-md tex-[15px]`}
                    onClick={() => {
                      setShowModal(true);
                      setItem(item);
                    }}
                  >
                    {item?.CouponCode ? "Show Code" : "Show Offer"}
                  </button>
                </div>
              </div>
            </div>
            </>

          );
        })
      ) : (
        <p className="text-[18px] text-[gray] w-[100%] py-[10px] text-center">
          No active offers available.
        </p>
      )}
      {/* ============= expire  */}
      {expiredOfferList && expiredOfferList?.length > 0 && (
        <div className="my-[30px]">
          <h2 className="mx-2 font-bold text-xl">Expired offers</h2>
          <div className="flex flex-col justify-start place-items-start gap-[20px] flex-wrap mt-5">
            {expiredOfferList &&
              expiredOfferList?.map((item, index) => {
                return (
                  <div
                    className="flex opacity-35 cursor-not-allowed  justify-start bg-[white] gap-[10px] place-items-start w-[100%]  flex-shrink-0 min-h-[150px] h-auto  rounded-lg hover:shadow-lg border-[1px] border-[#e4e3e3b2] shop_offer_list"
                    key={index}
                  >
                    <div className="w-[200px]  relative border-[1px] border-[#F2F3F4] flex-shrink-0 h-[100%] flex justify-between flex-col shop_offer_list_image_box">
                      {/* <img src={item?.store?.storeLogo.url} alt="" className='w-[200px] h-[100px] object-contain' /> */}
                      <div className="w-[200px] min-h-[130px]   flex justify-center place-items-center image_box_content_div">
                        <p className="font-bold w-[100%] text-center text-[22px] text-[#3F5BA9]">
                          {item?.discount
                            ? `${item?.discount}`
                            : item?.CouponCode
                            ? "Coupon"
                            : "FreeShpping"}
                        </p>
                      </div>
                      <p className="bg-primaryColor text-[white] text-center py-1">
                        {item?.discount
                          ? `Discount`
                          : item?.CouponCode
                          ? "Coupon"
                          : "FreeShpping"}{" "}
                      </p>
                    </div>
                    <div className="py-[12px] px-[7px]">
                      <h2 className=" w-[100%] py-[10px] text-left font-bold text-[16px]">
                        {item?.offerName?.length > 90
                          ? `${item?.offerName?.slice(0, 90)}...`
                          : item?.offerName}
                      </h2>
                      <p className="text-[14px] text-[gray] max-w-[100%]">
                        {item?.metaDescription?.slice(0, 200)}
                      </p>

                      <div className="w-[100%] flex justify-between place-items-center">
                        <p style={{ fontStyle: "italic" }} className="mt-[7px]">
                          <span className="font-medium mr-2">Expire</span>{" "}
                          <span className="text-[gray]">
                            {item?.startDate?.slice(0, 10)}
                          </span>
                        </p>

                        <button
                          className="bg-primaryColor mt-[13px] text-[white] py-[8px] px-[50px] cursor-not-allowed rounded-md tex-[15px]"
                          disabled={true}
                        >
                          {item?.CouponCode ? "Show Code" : "Show Offer"}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* ============= expire  */}
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferpageCompo;
