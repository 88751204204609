import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import Loading from '../spinner/Loading';
import "./Categories.css"

const Categories = () => {

    const categoryList = useSelector((state:any)=> state.category.categoryList)
    const categoryLoading = useSelector((state:any)=> state.category.categoryLoading)
     



  return (
    <div className='max-w-[2000px] 2xl:px-[150px] px-[10px] md:px-[50px] my-[10px] py-[20px] text-primaryColor'>
    <h1 className='text-center md:text-[30px] text-[23px] my-[20px]'>Popular Categories</h1>
     {
        categoryLoading ? 
        <Loading/>
        :
        <div className='flex justify-center place-items-center gap-[20px] flex-wrap mt-5'>
        { categoryList &&
         categoryList?.slice(-12)?.map((item:any,index:any)=>{
             return(
                 <NavLink to={`/categories/${item?.CategoryName}`} className='flex  justify-between gap-[10px] px-[20px] bg-[#F2F3F4] place-items-center w-[250px] flex-shrink-0 h-[70px] rounded-md hover:shadow-lg cursor-pointer border-[1px] border-[#e4e3e3b2] category_box' key={index}>
                       <h2 className='font-bold w-[100%] py-[10px] text-left  text-[14px]'>{item?.CategoryName}</h2>
                       <img src={item?.CategoryImage?.url} alt=""  className='w-[80px] h-[50px] object-cover rounded-md'/>
                     </NavLink>
             )
         })
        }
     </div>
     }

</div>
  )
}

export default Categories