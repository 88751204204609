import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

const Loading = () => {
  return (
   <div className='w-[100%] my-3 flex justify-center place-items-center'>
     <ClipLoader 
    color='black'
    size={80}
    loading={true}
    
    />
   </div>
  )
}

export default Loading
