import React from 'react'

const About = () => {
    return (
        <div className='max-w-[2000px] mx-auto'>
            <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <img className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx7VLIidzzHfQ2Umi4XskD1CD-CVs8Q36d5aK75cFcx1uYdGFFKdkBfH8-_WmskCH7gQ&usqp=CAU" />
                    <div className="text-left lg:w-2/3 w-full">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">About Us</h1>
                        <h1 className='text-[20px] font-medium my-3'>We are a UK company:</h1>
                        <p className='text-[20px] font-medium my-3'>Based in: UK</p>
                        <p className='text-[20px] font-medium my-3'>VAT registration number: UK 15868822</p>
                        <p className='text-[20px] font-medium my-3'>Email: Affiliatespot429@gmail.com</p>
                        <p className='text-[20px] font-medium my-3'>Phone: UK +442076085500</p>
                       <p className='my-2 mb-8 leading-relaxed'>
                       At couponsdiscount.uk, we're dedicated to saving you time by curating the best offers from various
                        brands. Easily discover top retailers on our platform without any hassle. Enjoy exclusive deals
                        and maximize your savings across a wide range of quality stores including clothing, electronics,
                        food, and more. Stay updated with the latest coupons by subscribing to our newsletter at
                        AFFLIATESPOT LTD.
                       </p>
                       <p className='text-[20px] font-bold my-3'> Liability notice</p>

                       
                       
                        <p className="my-2 leading-relaxed"> couponsdiscount.uk is not an e-commerce platform. We solely manage our website&#39;s content and
                        are not liable for external links. Linked pages are accountable for their content. For order
                        inquiries, kindly reach out to the respective online store..</p>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default About
