import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetStoreList } from '../redux/actions/StoreActions';
import Loading from '../Components/spinner/Loading';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';

const AllShops = () => {
    const dispatch = useDispatch()
    const StoreList = useSelector((state: any) => state.Store.StoreList);
    const storeLoading = useSelector((state: any) => state.Store.storeLoading);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const isSearch = searchParams.get('isSearch');
    const value = searchParams.get('value');
    const [searchStore , setSearcStore] = useState([])
  
    useEffect(() => {
      // Get the query parameters
      
      if (isSearch === 'true' && value) {
          setSearcStore(StoreList?.filter((item:any) => item?.storeName?.toLowerCase().includes(value.toLowerCase())))
      } 
    }, [searchParams]);
    useEffect(() => {
        // @ts-ignore
        dispatch(GetStoreList())

    }, [])
    return (
        <div className='max-w-[2000px] 2xl:px-[150px] px-[50px] my-[10px] py-[20px] text-primaryColor'>
            <h1 className='text-center md:text-[30px] text-[23px] my-[20px]'>Popular and new shops</h1>
            {
                storeLoading ?
                    <Loading />
                    :
                    <div className='flex justify-center place-items-center gap-[20px] flex-wrap mt-5'>
                        {StoreList && StoreList?.length > 0 ?
                           isSearch ? searchStore?.map((item: any, index: any) => {
                                return (
                                    <NavLink to={`/shop/${item?.storeName}`} className='flex flex-col justify-start gap-[10px] place-items-center w-[250px] flex-shrink-0 h-[190px] rounded-md hover:shadow-lg cursor-pointer border-[1px] border-[#e4e3e3b2]' key={index}>
                                        <img src={item?.storeLogo?.url} alt="" className='w-[200px] h-[115px] object-contain' />
                                        <h2 className='font-bold w-[100%] py-[10px] text-center border-t-[1px] border-t-[#e4e3e3b2] text-[14px]'>{item?.storeName}</h2>
                                    </NavLink>
                                )
                            }) : 
                            StoreList?.map((item: any, index: any) => {
                                return (
                                    <NavLink to={`/shop/${item?.storeName}`} className='flex flex-col justify-start gap-[10px] place-items-center w-[250px] flex-shrink-0 h-[190px] rounded-md hover:shadow-lg cursor-pointer border-[1px] border-[#e4e3e3b2]' key={index}>
                                        <img src={item?.storeLogo?.url} alt="" className='w-[200px] h-[115px] object-contain' />
                                        <h2 className='font-bold w-[100%] py-[10px] text-center border-t-[1px] border-t-[#e4e3e3b2] text-[14px]'>{item?.storeName}</h2>
                                    </NavLink>
                                )
                            })
                            : <p className='w-[100%] text-center text-[18px]'>No Store List Found</p>
                        }
                    </div>
            }

        </div>
    )
}


export default AllShops